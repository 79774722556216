import { acceptHMRUpdate, defineStore } from 'pinia'

const initialState = function () {
  return {
    openCount: 0,
  }
}

const state = initialState

export type MiscModalState = ReturnType<typeof state>

export const useMiscModalStore = defineStore('miscModal', {
  state: (): MiscModalState => ({ ...state() }),
  actions: {
    INCREASE_OPEN_COUNT() {
      this.openCount += 1
    },
    DECREASE_OPEN_COUNT() {
      this.openCount -= 1
    },
    OPEN() {
      this.INCREASE_OPEN_COUNT()
    },
    CLOSE() {
      this.DECREASE_OPEN_COUNT()
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscModalStore, import.meta.hot))
